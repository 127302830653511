import React from 'react'
import "./Our.css"
import pro from "./pro.png"
import { Link } from 'react-router-dom'
function Our() {
  return (
    <div className='our'>
    
      <div className="our-text">
        <h1>FUNc</h1>
        {/* <h3>
        <strong className='func'>FUNc</strong> is a health-tech program designed for busy individuals by spine specialists, physiotherapists and fitness experts.
        </h3> */}
        <p>After assessing 35,000 students, we discovered a startling trend: more than 90% of children lack basic movement competencies, and fewer than 1% are fit enough to participate in sports. To address this, FUNc is a dynamic platform dedicated to building skilled professionals and designing effective training programs that enhance movement abilities among children and young adults. We concentrate on developing expert coaches and curricula tailored for educational institutions. Based in Hyderabad, our FUNc School Of Movement empowers children through specialized programs that build physical strength and enhance their mental acumen, preparing them for healthier, more active lives.</p>

        <Link to="/myfunc"><button>Know more</button></Link>
      </div>
      <div className="our-img">
        <img src={pro} alt="" height={400}/>
      </div>
    </div>
  )
}

export default Our
